import React, { useEffect, useState, useRef } from 'react';
import { fetchServices } from './utils/servicesFetcher';

const App = () => {
  const [services, setServices] = useState([]);
  const locale = 'nl';
  const site = 'https://rups.cloud';
  const serviceRefs = useRef([]);

  useEffect(() => {
    console.log('Fetching services...');
    const loadServices = async () => {
      const fetchedServices = await fetchServices(site, locale);
      setServices(fetchedServices);
    };
    loadServices();
  }, [site, locale]);

  const handleKeyDown = (e) => {
    const focusedIndex = serviceRefs.current.findIndex(
      (ref) => ref === document.activeElement
    );
   
    if ((e.key === 'ArrowDown' || e.key === 'ArrowRight')) {
      e.preventDefault();
      serviceRefs.current[focusedIndex + 1]?.focus();
    } else if ((e.key === 'ArrowUp' || e.key === 'ArrowLeft') && focusedIndex > 0) {
      e.preventDefault();
      serviceRefs.current[focusedIndex - 1]?.focus();
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col bg-green-800 overflow-x-hidden"
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      <main className="flex-grow flex flex-col justify-center px-4 sm:px-6 lg:px-8 max-w-screen-lg lg:mx-auto w-full">
        <h1 className="text-3xl font-bold text-white mb-2">rups.cloud</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 bg-green-800">
          {services.map((service, index) => (
            <button
              key={service.title}
              ref={(el) => (serviceRefs.current[index] = el)}
              onClick={() => (window.location.href = service.url)}
              className="text-white shadow-lg p-4 rounded-lg border-t-4 border-green-500 bg-green-900 focus:ring-2 focus:ring-green-500 hover:bg-green-700 flex flex-col justify-center items-center group"
            >
              {service.icon && (
                <img
                  src={service.icon}
                  alt={service.title}
                  className="inline m-auto mb-2 w-20"
                />
              )}
              <span className="text-lg font-bold">
                {service.title}
              </span>
            </button>
          ))}
        </div>
      </main>
    </div>
  );
};

export default App;