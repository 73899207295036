import { fetchWithFilters } from 'rups-components';

export const fetchServices = async (site, locale) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  try {
    const services = await fetchWithFilters(apiUrl, 'projects', site, locale);
    return services.data;
  } catch (error) {
    console.error('Error fetching services:', error);
    return [];
  }
};
